import * as PIXI from 'pixi.js';
import axios from "axios";
import { gsap, Power4, Expo, Linear, Circ } from "gsap";
import { _pixi_math_extras } from '@pixi/math-extras';
import { sound } from '@pixi/sound';
import sky from "../img/sky.png";
import background from "../img/background.png";
import foreground01 from "../img/foreground/foreground01.png";
import foreground02 from "../img/foreground/foreground02.png";
import ground01 from "../img/ground/ground01.png";
import ground02 from "../img/ground/ground02.png";
import obstacle01 from "../img/obstacles/obstacle01.png";
import obstacle02 from "../img/obstacles/obstacle02.png";
import obstacle03 from "../img/obstacles/obstacle03.png";
import obstacle04 from "../img/obstacles/obstacle04.png";
import obstacle05 from "../img/obstacles/obstacle05.png";
import obstacle06 from "../img/obstacles/obstacle06.png";
import obstacle07 from "../img/obstacles/obstacle07.png";
import obstacle08 from "../img/obstacles/obstacle08.png";
import running1 from "../img/running/running01.png";
import running2 from "../img/running/running02.png";
import running3 from "../img/running/running03.png";
import running4 from "../img/running/running04.png";
import running5 from "../img/running/running05.png";
import jump1 from "../img/jump/jump01.png";
import jump2 from "../img/jump/jump02.png";
import jump3 from "../img/jump/jump03.png";
import jump4 from "../img/jump/jump04.png";
import jump5 from "../img/jump/jump05.png";
import jump6 from "../img/jump/jump06.png";
import jump7 from "../img/jump/jump07.png";
import crash1 from "../img/crash/crash01.png";
import crash2 from "../img/crash/crash02.png";
import crash3 from "../img/crash/crash03.png";
import crash4 from "../img/crash/crash04.png";
import crash5 from "../img/crash/crash05.png";
import crash6 from "../img/crash/crash06.png";
import collectable1 from "../img/collectables/collectable01.png";
import collectable2 from "../img/collectables/collectable02.png";
import collectable3 from "../img/collectables/collectable03.png";
import snowflake1 from "../img/snowflakes/snowflake01.png";
import snowflake2 from "../img/snowflakes/snowflake02.png";
import line from "../img/line.png";
import music from "../sound/music.mp3";
import jumpSound from "../sound/jump.mp3";
import dieSound from "../sound/die.mp3";
import collectSound from "../sound/collect.mp3";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function randomRange(minNum, maxNum) {
  return (Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum);
}

const logicalWidth = 1170;
const logicalHeight = 540;
const ratio = 19.5 / 9;

let type = "WebGL";
if (!PIXI.utils.isWebGLSupported()) {
  type = "canvas";
}

PIXI.utils.sayHello(type);
PIXI.SCALE_MODES.DEFAULT = PIXI.SCALE_MODES.NEAREST;
PIXI.settings.TARGET_FPMS = 0.06;

const app = new PIXI.Application({
    width: logicalWidth,
    height: logicalHeight,
    antialias: true,
    roundPixels: true,
    resolution: window.devicePixelRatio || 1
  }
);

app.renderer.backgroundColor = 0xffffff;

let playerName = '';
const startScreen = document.getElementsByClassName('start-screen-section').item(0);
const playerNameInput = document.getElementById('player_name');
const playButtonInitial = document.getElementById('playInitial');
const warningText = document.getElementsByClassName('start-screen-section__warning').item(0);
const mainContainer = document.getElementsByClassName('main-container').item(0);
const tutorial1 = document.getElementById('tutorial1');
const tutorial2 = document.getElementById('tutorial2');
const next1Button = document.getElementById('next1');
const playButtonFinal = document.getElementById('playFinal');



playerNameInput.addEventListener('input', (event) => {
  warningText.style.display = 'none';
});

playButtonInitial.addEventListener("click", () => {
  if (playerNameInput.value.length === 0) {
    warningText.style.display = 'block';
    return;
  }

  playerName = playerNameInput.value;
  document.body.style.backgroundColor = "#AAEBFF";

  startScreen.remove();

  tutorial1.style.display = "flex";
  // resizeHandler();
});

next1Button.addEventListener("click", () => {
  tutorial1.remove();
  tutorial2.style.display = "flex";
});

playButtonFinal.addEventListener("click", () => {
  tutorial2.remove();

  mainContainer.style.display = "block";
  mainContainer.appendChild(app.view);



  const isTouch = Boolean('ontouchstart' in window || navigator.maxTouchPoints);

  console.info('try to request fullscreen');
  console.info('isTouch:', isTouch);
  console.info('app.view.requestFullscreen:', app.view.requestFullscreen);
  if (isTouch && app.view.requestFullscreen) {
    console.info('request now');
    app.view.requestFullscreen();
  }

  PIXI.Loader.shared
  .add(sky)
  .add(background)
  .add([ground01, ground02])
  .add([foreground01, foreground02])
  .add([obstacle01, obstacle02, obstacle03, obstacle04, obstacle05, obstacle06, obstacle07, obstacle08])
  .add([running1, running2, running3, running4, running5])
  .add([crash1, crash2, crash3, crash4, crash5, crash6])
  .add([jump1, jump2, jump3, jump4, jump5, jump6, jump7])
  .add([collectable1, collectable2, collectable3])
  .add(snowflake1)
  .add(snowflake2)
  .add(line)
  .add(music)
  .add(jumpSound)
  .add(dieSound)
  .add(collectSound)
  .load(setup);


  resizeHandler();
});

const GAME_STATE_RUNNING = 'GAME_STATE_RUNNING';
const GAME_STATE_JUMPING1 = 'GAME_STATE_JUMPING1';
const GAME_STATE_JUMPING2 = 'GAME_STATE_JUMPING2';
const GAME_STATE_CRASHED = 'GAME_STATE_CRASHED';
const GAME_STATE_GAME_OVER = 'GAME_STATE_GAME_OVER';
const TERRAIN_GROUND1 = 'TERRAIN_GROUND1';
const TERRAIN_GROUND2 = 'TERRAIN_GROUND2';
const TERRAIN_OBSTACLE_PINE = 'TERRAIN_OBSTACLE_PINE';
const TERRAIN_OBSTACLE_STICKS = 'TERRAIN_OBSTACLE_STICKS';
const TERRAIN_OBSTACLE_ICE = 'TERRAIN_OBSTACLE_ICE';
const TERRAIN_OBSTACLE_TWO_PINES = 'TERRAIN_OBSTACLE_TWO_PINES';
const TERRAIN_OBSTACLE_FENCE = 'TERRAIN_OBSTACLE_FENCE';
const TERRAIN_OBSTACLE_ROCK = 'TERRAIN_OBSTACLE_ROCK';
const TERRAIN_OBSTACLE_SLEIGH = 'TERRAIN_OBSTACLE_SLEIGH';
const TERRAIN_OBSTACLE_BEAR = 'TERRAIN_OBSTACLE_BEAR';
const INITIAL_FOREGROUND_SPEED = 7;
const INITIAL_GROUND_SPEED = 4;
const INITIAL_BACK_SPEED = 0.5;
const INITIAL_SKY_SPEED = 0.3;
const CRASH_DECELERATION_FACTOR = 1.025;
const ACCELERATION_CONSTANT = 0.000015;


let score = 0;
let terrainCounter = 0;
let gameState = GAME_STATE_RUNNING;
const groundLevel = app.screen.height - 150;
const terrainLevel = app.screen.height;
const deerLevel = groundLevel + 10;
const deerJumpHeight = 75;
const giftLevelLow = groundLevel - 50;
const giftLevelHigh = groundLevel - 200;
let acceleration = 1;
let foregroundSpeed = INITIAL_FOREGROUND_SPEED;
let skySpeed = INITIAL_SKY_SPEED;
let groundSpeed = INITIAL_GROUND_SPEED;
let backSpeed = INITIAL_BACK_SPEED;
const skyContainer = new PIXI.Container();
const backgroundContainer = new PIXI.Container();
const terrainContainer = new PIXI.Container();
const collectablesContainer = new PIXI.Container();
let runningAnimatedSprite, jumpAnimatedSprite, crashAnimatedSprite;
const foregroundContainer = new PIXI.Container();
let scoreText = new PIXI.Text('0',{fontFamily : 'Noticia Text', fontSize: 24, fill : 0x000000, align : 'left'});
scoreText.x = 24;
scoreText.y = 100;
let gameOverContainer;
let standingData = [];
let playerData = {};




// SNOW
const snowFlakes = [];
const numSnowFlakes = 48;
const baseSpeedY = .002 * app.screen.height;
const baseSpeedX = .001 * app.screen.height;
const snowContainer = new PIXI.Container();

const initSnow = () => {
  app.stage.addChild(snowContainer);
  for(let i = 1; i <= numSnowFlakes; i++) {
    const image = snowflake1;
    let texture = PIXI.Texture.from(image);
    const flake = PIXI.Sprite.from(texture);
    flake.speedY = randomRange(2 * baseSpeedY, 3.6 * baseSpeedY);
    flake.angle = getRandomInt(0, 359);
    flake.speedX = -randomRange(5 * baseSpeedX, 8 * baseSpeedX);
    flake.x = Math.random() * (app.screen.width + 0.5 * app.screen.width);
    flake.y = Math.random() * app.screen.height;
    snowFlakes.push(flake);
    snowContainer.addChild(flake);
  }
};

const moveSnowflakes = (delta) => {
  for(let i = 0; i < snowFlakes.length; i++) {
    let snowFlake = snowFlakes[i];
    snowFlake.x += delta * snowFlake.speedX;
    snowFlake.y += delta * snowFlake.speedY;
    if(snowFlake.y > app.screen.height) {
      snowFlake.speedY = randomRange(2.5 * baseSpeedY, 4.6 * baseSpeedY);
      snowFlake.speedX = -randomRange(5 * baseSpeedX, 8 * baseSpeedX);
      snowFlake.y = -snowFlake.height;
      snowFlake.x = Math.random() * (app.screen.width + 0.5 * app.screen.width);
    }
  }
}

const jump = () => {
  if (gameState !== GAME_STATE_RUNNING && gameState !== GAME_STATE_JUMPING1) return;
  runningAnimatedSprite.visible = false;
  runningAnimatedSprite.stop();
  jumpAnimatedSprite.visible = true;
  jumpAnimatedSprite.gotoAndPlay(1);
  const soundInstance = sound.play(jumpSound);
  soundInstance.volume = .1;
  gsap.killTweensOf(jumpAnimatedSprite);
  gsap.to(jumpAnimatedSprite, {
    y: `-=${deerJumpHeight}`,
    duration: .6 / acceleration,
    ease: Expo.easeOut,
    onComplete: () => {
      gsap.to(
        jumpAnimatedSprite,{
          y: deerLevel,
          duration: .4 / acceleration,
          ease: Circ.easeIn,
          onComplete: () => {
            // console.info('gsap complete');
          }
        }
      )
    }
  });

  gameState = gameState === GAME_STATE_RUNNING ? GAME_STATE_JUMPING1 : GAME_STATE_JUMPING2;
};

const run = () => {
  runningAnimatedSprite.visible = true;
  runningAnimatedSprite.play();
  jumpAnimatedSprite.visible = false;
  jumpAnimatedSprite.stop();
  gameState = GAME_STATE_RUNNING;
};

const decelerateCrashSpeeds = () => {
  backSpeed /= CRASH_DECELERATION_FACTOR;
  groundSpeed /= CRASH_DECELERATION_FACTOR;
  foregroundSpeed /= CRASH_DECELERATION_FACTOR;
  if (foregroundSpeed < 0.05 && playerData.name && standingData.length > 0) {
    gameState = GAME_STATE_GAME_OVER;
    showGameOverScreen();
  }
};

const initSky = () => {
  app.stage.addChild(skyContainer);

  const skySprite = PIXI.Sprite.from(sky);
  skySprite.x = app.screen.width - (skySprite.width / 2);
  skyContainer.addChild(skySprite);
}

const moveSky = (delta) => {
  skyContainer.children.forEach(skyItem => {
    skyItem.x -= skySpeed * acceleration * delta;
    if (skyItem.x < -skyItem.width) {
      skyItem.x = app.screen.width + (skyItem.width / 4)
    }
  })
}

const initBackground = () => {
  backgroundContainer.y = 40;
  app.stage.addChild(backgroundContainer);

  const backgroundSprite1 = PIXI.Sprite.from(background);
  backgroundContainer.addChild(backgroundSprite1);

  const backgroundSprite2 = PIXI.Sprite.from(background);
  backgroundSprite2.x = backgroundSprite2.width;
  backgroundContainer.addChild(backgroundSprite2);
};

const moveBackground = (delta) => {
  backgroundContainer.x -= backSpeed * delta * acceleration;
  if (backgroundContainer.x < (-(backgroundContainer.width / 2))) {
    backgroundContainer.x = 0;
  }
};

const addSingleForegroundItem = () => {
  const item = PIXI.Sprite.from(getRandomInt(1, 2) === 1 ? foreground01 : foreground02);
  item.anchor.set(0.5, 1);
  const lastItem = foregroundContainer.children[foregroundContainer.children.length - 1];
  if (lastItem) {
    item.x = lastItem.x + lastItem.width + randomRange(app.screen.width, app.screen.width * 2);
  } else {
    item.x = randomRange(0, app.screen.width * 2);
  }
  item.y = randomRange(0, 140);
  item.angle = randomRange(-15, 15);
  foregroundContainer.addChild(item);
  return item;
}

const addForegroundItems = () => {
  const isSingleItem = getRandomInt(1, 3) === 1;
  if (isSingleItem) {
    addSingleForegroundItem();
  } else {
    const item1 = addSingleForegroundItem();
    const item2 = addSingleForegroundItem();
    item2.x = item1.x + item1.width + (randomRange(10, 80));
  }
}

const initForeground = () => {
  foregroundContainer.y = app.screen.height;
  app.stage.addChild(foregroundContainer);
  for (let i = 1; i <= 4; i++) {
    addForegroundItems();
  }
}

const moveForeground = (delta) => {
  const itemsToRemove = [];
  foregroundContainer.children.forEach(sprite => {
    sprite.x -= foregroundSpeed * acceleration * delta;
    if (sprite.x < -sprite.width) {
      itemsToRemove.push(sprite);
    }
  })
  itemsToRemove.forEach(sprite => {
    sprite.destroy();
  })
  if (foregroundContainer.width < 3 * app.view.width) {
    addForegroundItems();
  }
}

const getRandomSingleTerrainType = () => {
  const rand = getRandomInt(1, 3);
  switch (rand) {
    case 1:
      return TERRAIN_OBSTACLE_PINE;

    case 2:
      return TERRAIN_OBSTACLE_STICKS;

    case 3:
      return TERRAIN_OBSTACLE_ICE;

    default:
      return TERRAIN_OBSTACLE_PINE;
  }
};

const getRandomEmptyTerrainType = () => {
  return getRandomInt(1, 2) ?
    TERRAIN_GROUND1 :
    TERRAIN_GROUND2;
};

const getRandomDoubleTerrainType = () => {
  const rand = getRandomInt(1, 5);
  switch (rand) {
    case 1:
      return TERRAIN_OBSTACLE_TWO_PINES;

    case 2:
      return TERRAIN_OBSTACLE_FENCE;

    case 3:
      return TERRAIN_OBSTACLE_ROCK;

    case 4:
      return TERRAIN_OBSTACLE_SLEIGH;

    case 5:
      return TERRAIN_OBSTACLE_BEAR;

    default:
      return TERRAIN_OBSTACLE_TWO_PINES;
  }
};

const generateTerrain = () => {
  const numTerrainObjects = terrainContainer.children.length;
  const lastSprite = terrainContainer.children[numTerrainObjects - 1];
  const lastSpriteType = lastSprite.type;
  // console.info('----')
  // console.info('terrainCounter:', terrainCounter);
  // console.info('acceleration:', acceleration);


  if (terrainCounter < 35) {
    if (lastSpriteType === TERRAIN_OBSTACLE_PINE || lastSpriteType === TERRAIN_OBSTACLE_STICKS || lastSpriteType === TERRAIN_OBSTACLE_ICE) {
      const rand = getRandomInt(2, 4);
      for (let i = 1; i <= rand; i++) {
        addNewTerrainSprite(getRandomEmptyTerrainType(), true);
      }
    } else {
      addNewTerrainSprite(getRandomSingleTerrainType(), true);
    }
    return;
  }

  if (terrainCounter < 70) {
    if (lastSpriteType !== TERRAIN_GROUND1 && lastSpriteType !== TERRAIN_GROUND2) {
      const rand = getRandomInt(2, 3);
      for (let i = 1; i <= rand; i++) {
        addNewTerrainSprite(getRandomEmptyTerrainType(), true);
      }
    } else {
      if (getRandomInt(1, 2) === 1) {
        addNewTerrainSprite(getRandomSingleTerrainType(), true);
      } else {
        addNewTerrainSprite(getRandomDoubleTerrainType(), true);
      }
    }
    return;
  }


  if (lastSpriteType !== TERRAIN_GROUND1 && lastSpriteType !== TERRAIN_GROUND2) {
    if (lastSpriteType === TERRAIN_OBSTACLE_PINE || lastSpriteType === TERRAIN_OBSTACLE_STICKS || lastSpriteType === TERRAIN_OBSTACLE_ICE) {
      addNewTerrainSprite(TERRAIN_GROUND2, true);
    } else {
      addNewTerrainSprite(`TERRAIN_GROUND${getRandomInt(1, 2)}`, true);
    }
  } else {
    const rand = getRandomInt(1, 26);
    if (rand <= 10) {
      // single obstacle
      addNewTerrainSprite(getRandomSingleTerrainType(), true);
    } else if (rand <= 20) {
      // big obstacle
      addNewTerrainSprite(getRandomDoubleTerrainType(), true);
    } else {
      // ground
      const type = getRandomInt(1, 5) < 4 ? TERRAIN_GROUND2 : TERRAIN_GROUND1;
      addNewTerrainSprite(type, true);
    }
  }
};

const addNewTerrainSprite = (type, withCollectables = false) => {
  let texture;
  switch (type) {
    case TERRAIN_GROUND1:
      texture = ground01;
      break;

    case TERRAIN_GROUND2:
      texture = ground02;
      break;

    case TERRAIN_OBSTACLE_PINE:
      texture = obstacle01;
      break;

    case TERRAIN_OBSTACLE_STICKS:
      texture = obstacle06;
      break;

    case TERRAIN_OBSTACLE_ICE:
      texture = obstacle08;
      break;

    case TERRAIN_OBSTACLE_TWO_PINES:
      texture = obstacle02;
      break;

    case TERRAIN_OBSTACLE_FENCE:
      texture = obstacle03;
      break;

    case TERRAIN_OBSTACLE_ROCK:
      texture = obstacle04;
      break;

    case TERRAIN_OBSTACLE_SLEIGH:
      texture = obstacle05;
      break;

    case TERRAIN_OBSTACLE_BEAR:
      texture = obstacle07;
      break;

    default:
      texture = ground01;
  }

  const currentNumTerrainObjects = terrainContainer.children.length;
  const lastTerrainSprite = terrainContainer.children[currentNumTerrainObjects - 1];

  const sprite = PIXI.Sprite.from(texture);
  sprite.anchor.set(0, 1);
  sprite.x = lastTerrainSprite ? lastTerrainSprite.x + lastTerrainSprite.width : 0;
  sprite.y = terrainLevel;
  sprite.type = type;
  terrainContainer.addChild(sprite);

  if (withCollectables) {
    addCollectablesForTerrain(sprite);
  }
  terrainCounter += 1;
}

const initTerrain = () => {
  if (!terrainContainer.parent) {
    app.stage.addChild(terrainContainer);
  }

  while (terrainContainer.children.length > 0) {
    terrainContainer.children[0].destroy({ children: true });
  }

  for (let i = 0; i < 10; i++) {
    addNewTerrainSprite(`TERRAIN_GROUND${getRandomInt(1, 2)}`, i > 3);
  }
}

const moveTerrain = (delta) => {
  const numTerrainObjects = terrainContainer.children.length;
  const terrainsToRemove = [];
  for (let i = 0; i < numTerrainObjects; i++) {
    const sprite = terrainContainer.children[i];
    sprite.x -= delta * groundSpeed * acceleration;
    if (sprite.x < -sprite.width) {
      terrainsToRemove.push(sprite);
    }
  }
  terrainsToRemove.forEach(sprite => {
    sprite.destroy();
  });
  if (terrainContainer.width < 2 * app.screen.width) {
    generateTerrain();
  }
};

const initRunningDeer = () => {
  const runningImages = [running1, running2, running3, running4, running5];
  const runningTextureArray = [];
  for (let i = 0; i < 4; i++) {
    let texture = PIXI.Texture.from(runningImages[i]);
    runningTextureArray.push(texture);
  }
  runningAnimatedSprite = new PIXI.AnimatedSprite(runningTextureArray);
  runningAnimatedSprite.animationSpeed = 0.13 / acceleration;
  runningAnimatedSprite.x = app.screen.width / 4;
  runningAnimatedSprite.y = deerLevel;
  runningAnimatedSprite.anchor.set(0.5, 1);
  runningAnimatedSprite.play();
  app.stage.addChild(runningAnimatedSprite);
};

const getDeerRect = () => {
  let deerRect = gameState === GAME_STATE_RUNNING ?
    runningAnimatedSprite.getBounds() :
    jumpAnimatedSprite.getBounds();
  deerRect.width = deerRect.width - 20;
  return deerRect;
}

const checkForCollision = () => {
  if (gameState === GAME_STATE_CRASHED) return;
  const deerRect = getDeerRect();
  terrainContainer.children.forEach(terrain => {
    if (terrain.type !== TERRAIN_GROUND1 && terrain.type !== TERRAIN_GROUND2) {
      const obstacleRect = terrain.getBounds();
      let innerRect;
      switch (terrain.type) {
        case TERRAIN_OBSTACLE_PINE:
          innerRect = new PIXI.Rectangle(
            obstacleRect.x + 41,
            obstacleRect.y + 5,
            29,
            57
          );
          break;

        case TERRAIN_OBSTACLE_STICKS:
          innerRect = new PIXI.Rectangle(
            obstacleRect.x + 41,
            obstacleRect.y + 5,
            29,
            57
          );
          break;

        case TERRAIN_OBSTACLE_ICE:
          innerRect = new PIXI.Rectangle(
            obstacleRect.x + 41,
            obstacleRect.y + 5,
            29,
            57
          );
          break;

        case TERRAIN_OBSTACLE_TWO_PINES:
          innerRect = new PIXI.Rectangle(
            obstacleRect.x + 69,
            obstacleRect.y + 20,
            108,
            102
          );
          break;

        case TERRAIN_OBSTACLE_FENCE:
          innerRect = new PIXI.Rectangle(
            obstacleRect.x + 54,
            obstacleRect.y,
            122,
            54
          );
          break;

        case TERRAIN_OBSTACLE_ROCK:
          innerRect = new PIXI.Rectangle(
            obstacleRect.x + 80,
            obstacleRect.y,
            114,
            56
          );
          break;

        case TERRAIN_OBSTACLE_SLEIGH:
          innerRect = new PIXI.Rectangle(
            obstacleRect.x + 70,
            obstacleRect.y,
            142,
            67
          );
          break;

        case TERRAIN_OBSTACLE_BEAR:
          innerRect = new PIXI.Rectangle(
            obstacleRect.x + 121,
            obstacleRect.y + 15,
            142,
            67
          );
          break;

        default:
          break;
      }
      if (deerRect.intersects(innerRect)) {
        crash();
      }
    }
  });
  collectablesContainer.children.forEach(collectable => {
    const collectableRect = collectable.getBounds();
    if (!collectable.isCollected && deerRect.intersects(collectableRect)) {
      score += collectable.score;
      const soundInstance = sound.play(collectSound);
      soundInstance.volume = .1;
      collectable.isCollected = true;
      gsap.to(collectable, {
        duration: .8 / acceleration,
        y: "-=60",
        alpha: 0,
        ease: Power4.easeOut,
        onComplete: () => collectable.destroy()
      });
    }
  });
};

const restartGame = () => {
  gameOverContainer.destroy({ children: true });
  crashAnimatedSprite.destroy({ children: true });
  while (collectablesContainer.children.length > 0) {
    collectablesContainer.children[0].destroy({ children: true });
  }
  score = 0;
  terrainCounter = 0;
  acceleration = 1;
  backSpeed = INITIAL_BACK_SPEED;
  groundSpeed = INITIAL_GROUND_SPEED;
  foregroundSpeed = INITIAL_FOREGROUND_SPEED;
  initTerrain();
  initRunningDeer();
  initJumpDeer();
  gameState = GAME_STATE_RUNNING;
};

const getPlayerTextField = (name, score, color = 0x2C3339) => {
  const container = new PIXI.Container();

  const playerNameTextField = new PIXI.Text('0',{fontFamily : 'Noticia Text', fontSize: 20, fill : color, align : 'left'});
  playerNameTextField.text = name;
  container.addChild(playerNameTextField);

  const scoreTextField = new PIXI.Text('0',{fontFamily : 'Noticia Text', fontSize: 20, fill : color, align : 'left'});
  scoreTextField.text = String(score);
  scoreTextField.x = 225 - scoreTextField.width;
  container.addChild(scoreTextField);

  return container;
};

const getPlayAgainButton = () => {
  const container = new PIXI.Container();

  const background = new PIXI.Graphics();
  background.beginFill(0xFF6161);
  background.drawRoundedRect(0, 0, 160, 48, 24);
  background.endFill();
  container.addChild(background);

  const textField = new PIXI.Text('0',{fontFamily : 'Noticia Text', fontSize: 20, fill : 0xffffff, align : 'center'});
  textField.text = "Play again";
  textField.x = (background.width - textField.width) * .5;
  textField.y = (background.height - textField.height) * .5;
  container.addChild(textField);

  return container;
};

const getGameOverBackground = () => {
  const background = new PIXI.Graphics();
  background.beginFill(0xFF9E89);
  background.drawRoundedRect(
    0,
    0,
    app.screen.width * .8,
    app.screen.height * .8,
    32
  );
  background.endFill();
  return background;
};

const getGameOverTitle = () => {
  const textField = new PIXI.Text('0',{fontFamily : 'Noticia Text', fontSize: 28, fill : 0xffffff, align : 'center'});
  textField.text = 'Best Christmas runners';
  return textField;
};

const getStandingsTable = () => {
  const tableContainer = new PIXI.Container();
  if (standingData.length > 0 && playerData.name) {
    standingData.forEach((item, index) => {
      const p = getPlayerTextField(`${item.position}. ${item.name}`, item.score);

      if (app.screen.width < 600) {
        p.y = index * 36;
      } else {
        if (index < 5) {
          p.y = index * 36;
        } else {
          p.x = app.screen.width * .35;
          p.y = (index - 5) * 36;
        }
      }
      const lineSprite = PIXI.Sprite.from(line);
      lineSprite.x = p.x + ((p.width - lineSprite.width) * .5);
      lineSprite.y = p.y + p.height;
      tableContainer.addChild(p);
      tableContainer.addChild(lineSprite);
    });
  }
  return tableContainer;
};



const showGameOverScreen = () => {
  gameOverContainer = new PIXI.Container();

  const background = getGameOverBackground();
  gameOverContainer.addChild(background);

  const title = getGameOverTitle();
  gameOverContainer.addChild(title);

  const table = getStandingsTable();
  gameOverContainer.addChild(table);

  // Check if we have data!
  const myPosition = getPlayerTextField(`${playerData.position}. ${playerData.name}`, playerData.score, 0xf9f9f9);
  gameOverContainer.addChild(myPosition);

  const playAgain = getPlayAgainButton();
  gameOverContainer.addChild(playAgain);

  const verticalSpace = background.height - title.height - table.height - myPosition.height - playAgain.height;
  const space = verticalSpace / 5;

  title.x = (background.width - title.width) * .5;
  title.y = space;

  table.x = (background.width - table.width) * .5 + 40;
  table.y = title.y + title.height + space;

  myPosition.x = (background.width - myPosition.width) * .5;
  myPosition.y = table.y + table.height + space;

  playAgain.x = (background.width - playAgain.width) * .5;
  playAgain.y = myPosition.y + myPosition.height + space;
  playAgain.interactive = true;
  playAgain.buttonMode = true;
  playAgain.on('pointerdown', restartGame);

  gameOverContainer.x = (app.screen.width - gameOverContainer.width) * .5;
  gameOverContainer.y = (app.screen.height - gameOverContainer.height) * .5;

  app.stage.addChild(gameOverContainer);
};

const crash = () => {
  gameState = GAME_STATE_CRASHED;
  runningAnimatedSprite.destroy();
  gsap.killTweensOf(jumpAnimatedSprite);
  jumpAnimatedSprite.destroy();
  const soundInstance = sound.play(dieSound);
  soundInstance.volume = .1;
  const crashImages = [crash1, crash2, crash3, crash4, crash5, crash6];
  const crashTextureArray = [];
  crashImages.forEach(image => {
    let texture = PIXI.Texture.from(image);
    crashTextureArray.push(texture);
  });
  crashAnimatedSprite = new PIXI.AnimatedSprite(crashTextureArray);
  crashAnimatedSprite.animationSpeed = 0.13 / acceleration;
  crashAnimatedSprite.x = app.screen.width / 4;
  crashAnimatedSprite.y = deerLevel;
  crashAnimatedSprite.anchor.set(0.5, 1);
  crashAnimatedSprite.gotoAndPlay(1);
  crashAnimatedSprite.loop = false;
  crashAnimatedSprite.onComplete = () => {
    crashAnimatedSprite.stop();
  }
  app.stage.addChild(crashAnimatedSprite);

  standingData = [];
  playerData = {};

  axios.post('https://chgame.droxic.com/api/players', {
    name: playerName,
    score: score
  })
    .then(function (response) {

      axios.get('https://chgame.droxic.com/api/players?top=10')
        .then(function (response) {
          standingData = [ ...response.data ];


          axios.get(`https://chgame.droxic.com/api/players?name=${playerName}`)
            .then(function (response) {
              playerData = { ...response.data };
            })
            .catch(function (error) {
              // console.log(error);
            })


        })
        .catch(function (error) {
          // console.log(error);
        })

    })
    .catch(function (error) {
      // console.log(error);
    });
};

const moveCrashedDeer = (delta) => {
  if (gameState === GAME_STATE_CRASHED) {
    crashAnimatedSprite.x -= delta * groundSpeed * acceleration;
  }
};

const initJumpDeer = () => {
  const jumpImages = [jump1, jump2, jump3, jump4, jump4, jump4, jump5, jump6, jump7];
  const jumpTextureArray = [];

  jumpImages.forEach(jump => {
    let texture = PIXI.Texture.from(jump);
    jumpTextureArray.push(texture);
  });

  jumpAnimatedSprite = new PIXI.AnimatedSprite(jumpTextureArray);
  jumpAnimatedSprite.animationSpeed = 0.13 / acceleration;
  jumpAnimatedSprite.x = app.screen.width / 4;
  jumpAnimatedSprite.y = deerLevel;
  jumpAnimatedSprite.anchor.set(0.5, 1);
  jumpAnimatedSprite.visible = false;
  jumpAnimatedSprite.loop = false;
  jumpAnimatedSprite.onComplete = function () {
    if (gameState === GAME_STATE_JUMPING1 || gameState === GAME_STATE_JUMPING2) {
      run();
      // console.info('sprite animation complete');
    }
  };
  app.stage.addChild(jumpAnimatedSprite);
};

const accelerate = () => {
  acceleration += ACCELERATION_CONSTANT;
};

const initCollectables = () => {
  app.stage.addChild(collectablesContainer);
};

const addCollectablesForTerrain = terrainObject => {
  const collectableScore = getRandomInt(1, 3);

  if (terrainObject.type === TERRAIN_GROUND1) {
    const spreadScheme = getRandomInt(1, 12); // We have 9
    switch (spreadScheme) {
      case 1:
        for (let i = 0; i < 4; i++) {
          const space = terrainObject.width / 4;
          const sprite = getCollectable(collectableScore);
          sprite.x = terrainObject.x + (i * space) + ((space - sprite.width) / 2);
          sprite.y = giftLevelLow;
        }
        break;

      case 2:
        for (let i = 0; i < 4; i++) {
          if (i === 0 || i === 2) continue;
          const space = terrainObject.width / 4;
          const sprite = getCollectable(collectableScore);
          sprite.x = terrainObject.x + (i * space) + ((space - sprite.width) / 2);
          sprite.y = giftLevelLow;
        }
        break;

      case 3:
        for (let i = 0; i < 4; i++) {
          const space = terrainObject.width / 4;
          const sprite = getCollectable(collectableScore);
          sprite.x = terrainObject.x + (i * space) + ((space - sprite.width) / 2);
          sprite.y =  i <= 1 ? giftLevelHigh : giftLevelLow;
        }
        break;

      case 4:
        for (let i = 0; i < 4; i++) {
          const space = terrainObject.width / 4;
          const sprite = getCollectable(collectableScore);
          sprite.x = terrainObject.x + (i * space) + ((space - sprite.width) / 2);
          sprite.y = giftLevelHigh;
        }
        break;

      case 5:
        for (let i = 0; i < 4; i++) {
          const space = terrainObject.width / 4;
          const sprite = getCollectable(collectableScore);
          sprite.x = terrainObject.x + (i * space) + ((space - sprite.width) / 2);
          sprite.y = i === 0 || i === 3 ? giftLevelLow : giftLevelHigh;
        }
        break;

      case 6:
        for (let i = 0; i < 2; i++) {
          const space = terrainObject.width / 4;
          const sprite = getCollectable(collectableScore);
          sprite.x = terrainObject.x + (i * space) + ((space - sprite.width) / 2);
          sprite.y = giftLevelLow;
        }
        break;

      case 7:
        for (let i = 2; i < 4; i++) {
          const space = terrainObject.width / 4;
          const sprite = getCollectable(collectableScore);
          sprite.x = terrainObject.x + (i * space) + ((space - sprite.width) / 2);
          sprite.y = giftLevelLow;
        }
        break;

      case 8:
        for (let i = 0; i < 2; i++) {
          const space = terrainObject.width / 4;
          const sprite = getCollectable(collectableScore);
          sprite.x = terrainObject.x + (i * space) + ((space - sprite.width) / 2);
          sprite.y = giftLevelHigh;
        }
        break;

      case 9:
        for (let i = 2; i < 4; i++) {
          const space = terrainObject.width / 4;
          const sprite = getCollectable(collectableScore);
          sprite.x = terrainObject.x + (i * space) + ((space - sprite.width) / 2);
          sprite.y = giftLevelHigh;
        }
        break;

      default:
        break;
    }
  } else {
    const spreadScheme = getRandomInt(1, 7);
    switch (spreadScheme) {
      case 1:
        for (let i = 0; i < 4; i++) {
          const space = terrainObject.width / 4;
          const sprite = getCollectable(collectableScore);
          sprite.x = terrainObject.x + (i * space) + ((space - sprite.width) / 2);
          sprite.y = giftLevelHigh;
        }
        break;

      case 2:
        for (let i = 0; i < 2; i++) {
          const space = terrainObject.width / 4;
          const sprite = getCollectable(collectableScore);
          sprite.x = terrainObject.x + (i * space) + ((space - sprite.width) / 2);
          sprite.y = giftLevelHigh;
        }
        break;

      case 3:
        for (let i = 2; i < 4; i++) {
          const space = terrainObject.width / 4;
          const sprite = getCollectable(collectableScore);
          sprite.x = terrainObject.x + (i * space) + ((space - sprite.width) / 2);
          sprite.y = giftLevelHigh;
        }
        break;

      case 4:
        for (let i = 1; i < 3; i++) {
          const space = terrainObject.width / 4;
          const sprite = getCollectable(collectableScore);
          sprite.x = terrainObject.x + (i * space) + ((space - sprite.width) / 2);
          sprite.y = giftLevelHigh;
        }
        break;

      default:
        break;
    }
  }
};

const getCollectable = (collectableScore) => {
  let image;
  switch (collectableScore) {
    case 1:
      image = collectable1;
      break;

    case 2:
      image = collectable2;
      break;

    case 3:
      image = collectable3;
      break;

    default:
      image = collectable1;
      break;
  }

  let texture = PIXI.Texture.from(image);
  const sprite = PIXI.Sprite.from(texture);
  sprite.score = collectableScore;
  collectablesContainer.addChild(sprite);
  return sprite;
}

const moveCollectables = (delta) => {
  const collectablesToRemove = [];
  collectablesContainer.children.forEach(collectable => {
    collectable.x -= delta * groundSpeed * acceleration;
    if (collectable.x < -collectable.width) {
      collectablesToRemove.push(collectable);
    }
  });
  collectablesToRemove.forEach(collectable => {
    collectable.destroy();
  });
};

const resizeHandler = () => {
  document.body.style.height = `${window.innerHeight}px`;
  document.body.style.width = `${window.innerWidth}px`;
  document.documentElement.style.height = `${window.innerHeight}px`;
  document.documentElement.style.width = `${window.innerWIdth}px`;
  mainContainer.style.height = `${window.innerHeight}px`;
  mainContainer.style.width = `${window.innerWidth}px`;
  tutorial1.style.height = `${window.innerHeight}px`;
  tutorial1.style.width = `${window.innerWidth}px`;

  let newWidth = window.innerWidth;
  let newHeight = window.innerWidth / ratio;

  if (newWidth > logicalWidth) {
    newWidth = logicalWidth;
    newHeight = logicalWidth / ratio;
  }

  if (newHeight > logicalHeight) {
    newHeight = logicalHeight;
    newWidth = newHeight * ratio;
  }

  if (newHeight > window.innerHeight) {
    newHeight = window.innerHeight;
    newWidth = newHeight * ratio;
  }

  app.view.style.width = `${newWidth}px`;
  app.view.style.height = `${newHeight}px`;

  app.resize(newWidth, newHeight);
};

function setup() {
  window.addEventListener('resize', resizeHandler, false);
  resizeHandler();
  initSky();
  initBackground();
  initTerrain();
  initRunningDeer();
  initJumpDeer();
  initCollectables();
  initForeground();
  initSnow();
  // Keyboard and touch/click events
  app.stage.interactive = true;
  app.renderer.view.addEventListener('pointerdown', () => {
    jump();
  });

  document.addEventListener('keydown', (event) => {
    if (event.code === "Space") {
      jump();
    }
  });

  // Score text
  app.stage.addChild(scoreText);

  // SOUND
  const soundInstance = sound.play(music);
  soundInstance.volume = 0.3;
  soundInstance.loop = true;

  app.ticker.add((delta) => {
    moveSky(delta);
    moveSnowflakes(delta);

    if (gameState === GAME_STATE_CRASHED) {
      decelerateCrashSpeeds();
    }

    if (gameState !== GAME_STATE_GAME_OVER) {
      moveBackground(delta);
      moveForeground(delta);
      moveTerrain(delta);
      moveCollectables(delta);
      moveCrashedDeer(delta);
      checkForCollision();
      scoreText.text = score;
    }

    if (gameState !== GAME_STATE_CRASHED && gameState !== GAME_STATE_GAME_OVER) {
      accelerate();
    }
  });
}
